import React, { Component } from "react";
import { supabase } from "../supabaseClient";
import Dashboard from "./dashboard";
import Auth from "./auth/Auth";
import { Button, Container, Row, Col } from "react-bootstrap";

class Main extends Component {
  state = { isLoggedIn: false, loading: true, user: null };
  signOut = () => {
    supabase.auth.signOut();
    this.setState({
      isLoggedIn: false,
      token: null,
    });
  };

  setSession = (data) => {
    supabase.auth.setSession(data);
    this.getUser();
  };

  getUser = async () => {
    const { data, error } = await supabase.auth.getSession();
    if (!error && data["session"]) {
      let access_token = data.session.access_token;
      this.setState({ isLoggedIn: true, token: access_token });
    }
  };
  componentDidUpdate() {
    console.log(this.state);
  }
  componentDidMount() {
    this.getUser();
    supabase.auth.onAuthStateChange((event, session) => {});
  }

  render() {
    if (!this.state.isLoggedIn) {
      return (
        <div>
          <p>You are not logged in</p>
          <Auth
            setSession={(data) => {
              this.setSession(data);
            }}
          />
        </div>
      );
    } else {
      return (
        <div className="mt-3 mb-5">
          <Container></Container>
          <Row className="justify-content-end">
            <Col>
              <Button className="m-5" onClick={this.signOut}>
                Sign Out
              </Button>
            </Col>
          </Row>
          <Row>
            <Dashboard token={this.state.token} />
          </Row>
        </div>
      );
    }
  }
}

export default Main;
