import React, { Component } from "react";
import { Accordion, Row, Button, Card } from "react-bootstrap";
import VenueFilter from "./VenueFIlter";
import DateFilter from "./DateFilter";

class Filters extends Component {
  state = {};
  render() {
    return (
      <Accordion defaultActiveKey={["0"]} alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Filter Data</Accordion.Header>
          <Accordion.Body>
            <VenueFilter
              venues={this.props.venues}
              setVenue={(id, state) => {
                this.props.setVenue(id, state);
              }}
            />
            <DateFilter
              bubble={(data) => {
                this.props.dateBubble(data);
              }}
            />
            <Button className="mt-2 mb-2" onClick={this.props.applyFilter}>
              Apply
            </Button>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  }
}

export default Filters;
