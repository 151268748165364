import React, { Component } from "react";
import { Table } from "react-bootstrap";
import Form from "react-bootstrap/Form";

class VenueFilter extends Component {
  state = {};
  render() {
    if (this.props.venues.length > 0) {
      return (
        <>
          <h4>Filter By Venue</h4>
          <Form>
            <Table>
              <tr>
                <th>
                  Select
                  <Form.Check
                    type="checkbox"
                    defaultChecked="true"
                    id="master"
                    onInput={(e) => {
                      console.log("This box is", e.target.checked);
                      this.props.venues.forEach((venue) => {
                        this.props.setVenue(venue.id, e.target.checked);
                      });
                      let boxes =
                        document.querySelectorAll(".venue_check input");
                      boxes.forEach((box) => {
                        box.checked = e.target.checked;
                      });
                    }}
                  />
                </th>
                <th>Venue</th>
                <th>Incidents?</th>
                <th>Attendance?</th>
              </tr>

              {this.props.venues.map((venue, index) => {
                return (
                  <tr
                    className={venue.isSelected ? "bg-light" : "bg-secondary"}
                  >
                    <td>
                      <Form.Check
                        type="checkbox"
                        defaultChecked="true"
                        id={venue.id}
                        className="venue_check"
                        onInput={(e) => {
                          this.props.setVenue(e.target.id, e.target.checked);
                        }}
                      />
                    </td>
                    <td>{venue.venue_name}</td>
                    <td>
                      <p>{venue.hasIncidents ? "✅" : "❌"}</p>
                    </td>
                    <td>
                      <p>{venue.hasattendance ? "✅" : "❌"}</p>
                    </td>
                  </tr>
                );
              })}
            </Table>
          </Form>
        </>
      );
    } else {
      return (
        <Form.Select aria-label="Default select example">
          <option>Loading</option>
        </Form.Select>
      );
    }
  }
}

export default VenueFilter;
