import React, { Component } from "react";
import DatePicker from "./datePicker";
import { Form, Row, Container } from "react-bootstrap";

class DateFilter extends Component {
  componentDidUpdate = () => {};
  state = {};
  datePreset = (less) => {
    let today = new Date();
    let start = new Date();
    start.setDate(start.getDate() - less);
    console.log(today.toDateString(), start.toDateString());
    let to = document.querySelector("#To");
    let from = document.querySelector("#From");
    to.value = this.getDateString(today);
    from.value = this.getDateString(start);
    this.props.bubble({ to: to.value, from: from.value });
  };
  getDateString = (date) => {
    console.log(date.getDay());
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    console.log("Year: ", year, " Month: ", month, " Day: ", day);
    let string = `${year}-${month}-${day}`;
    return string;
  };

  render() {
    return (
      <div>
        <Container>
          <Row className="mt-5">
            <h4>Filter By Date</h4>
            <DatePicker
              label="From"
              bubble={(data) => {
                this.props.bubble({ from: data });
              }}
            />
            <DatePicker
              label="To"
              bubble={(data) => {
                this.props.bubble({ to: data });
              }}
            />
          </Row>
          <Row className="mt-3 mb-5">
            <Form.Select
              onInput={(e) => {
                this.datePreset(e.target.value);
              }}
            >
              <option>Select a Preset</option>
              <option value="7">Last 7 Days</option>
              <option value="28">Last 4 Weeks</option>
              <option value="365">Last Year</option>
            </Form.Select>
          </Row>
        </Container>
      </div>
    );
  }
}

export default DateFilter;
