import React, { Component } from "react";
import DateFilter from "./DateFilter";
import DatePicker from "./datePicker";
import ScoreCard from "./ScoreCard";
import VenueFilter from "./VenueFIlter";
import axios from "axios";
import { Button, Container, Row, Spinner } from "react-bootstrap";
import ApexChart from "./ApexChart";
import Filters from "./FIlters";
import LeagueTable from "./LeagueTable";

class Dashboard extends Component {
  componentDidMount = async () => {
    let venues = await this.getVenues(this.props.token);
    // this.getVenues(this.props.token);
    venues = venues.map((venue) => {
      venue.isSelected = true;
      return venue;
    });
    this.getIncidents(this.props.token);
    this.getAttendance(this.props.token);
    this.setState({ token: this.props.token, venues: venues });
  };
  calculateTotals = () => {
    // Sum attendance
    let attendance = [];
    this.state.venues.forEach((venue) => {
      // Check if venue is selected, if it is add it to the attendance
      if (venue.isSelected) {
        let venueAttendance = venue.attendance;
        if (this.state.to && this.state.from) {
          // Filter the venue attendance inline with the date
          venueAttendance = venueAttendance.filter((attend) => {
            if (
              attend.date >= this.state.from &&
              attend.date <= this.state.to
            ) {
              return true;
            }
          });
        }
        attendance = [...attendance, ...venueAttendance];
      }
    });
    if (this.state.attendance) {
      let total = attendance.reduce(function (acc, obj) {
        return acc + obj.total_attended;
      }, 0);
      this.setState({ totalattendance: total, attendance });
    }
    let incidents = [];
    this.state.venues.forEach((venue) => {
      if (venue.isSelected) {
        let venueIncidents = venue.incidents;
        if (this.state.to && this.state.from) {
          // Filter inline with the date
          let to = new Date(this.state.to);
          let from = new Date(this.state.from);
          venueIncidents = venueIncidents.filter((incident) => {
            let date = new Date(incident.incident_date);
            if (date >= from && date <= to) {
              return true;
            }
          });
        }
        incidents = [...incidents, ...venueIncidents];
      }
    });
    let riddors = incidents.filter((incident) => incident.riddor);
    this.incidentByLocationFixed(incidents);
    this.incidentByType(incidents);

    this.setState({
      totalIncidents: incidents.length,
      riddors,
      totalRiddors: riddors.length,
    });
  };
  applyFilter = () => {
    this.calculateTotals();
  };
  componentDidUpdate() {
    if (this.state.attendance && this.state.incidents) {
      console.log(
        "attendance: ",
        this.state.attendance.length,
        " Incidents: ",
        this.state.incidents.length
      );
    }
  }
  setVenue = (id, value) => {
    let venues = this.state.venues;
    let index = venues.findIndex((venue) => venue.id == id);
    venues[index].isSelected = value;
  };

  getVenues = async (token) => {
    this.setState({ loading: true });
    let response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/venues`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data[0];
    // this.setState({ venues: response.data[0], loading: false });
  };
  incidentByLocationFixed = (incidents) => {
    let locations = {};

    incidents.forEach((incident) => {
      let location = incident["incident_location_fixed_value"];

      if (!location) {
        return;
      }
      if (locations[location]) {
        locations[location].count++;
        return;
      }
      locations[location] = {
        name: location,
        count: 1,
      };
      return;
    });

    const labels = Object.keys(locations);
    let data = labels.map((location) => {
      return locations[location].count;
    });

    let chartData = {
      labels,
      series: data,
    };
    this.setState({ incidentByLocationFixed: chartData });
  };

  incidentByType = (incidents) => {
    let locations = {};

    incidents.forEach((incident) => {
      let location = incident["incident_location"];

      if (!location) {
        return;
      }
      if (locations[location]) {
        locations[location].count++;
        return;
      }
      locations[location] = {
        name: location,
        count: 1,
      };
      return;
    });

    const labels = Object.keys(locations);
    let data = labels.map((location) => {
      return locations[location].count;
    });

    let chartData = {
      labels,
      series: data,
    };
    this.setState({ incidentByType: chartData });
  };
  getIncidents = async (token, venue) => {
    this.setState({ loading: true });
    let response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/incidents`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    let riddors = response.data.filter((incident) => {
      return incident.riddor;
    });
    this.incidentByLocationFixed(response.data);
    this.incidentByType(response.data);

    let venues = this.state.venues;
    venues = venues.map((venue) => {
      let incidents = response.data.filter((incident) => {
        return incident.venue === venue.venue_name;
      });
      if (incidents.length > 9) {
        venue.hasIncidents = true;
      } else {
        venue.hasIncidents = false;
      }
      venue.incidents = incidents;
      return venue;
    });
    this.setState({
      venues,
      incidents: response.data,
      totalIncidents: response.data.length,
      riddors: riddors,
      totalRiddors: riddors.length,
      loading: false,
    });
  };
  getAttendance = async (token) => {
    this.setState({ loading: true });
    let response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/attendance`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    let venues = this.state.venues;
    venues = venues.map((venue) => {
      let attendance = response.data.filter((attend) => {
        return attend.venue_id === venue.id;
      });
      if (attendance.length > 0) {
        venue.hasattendance = true;
      } else {
        venue.hasattendance = false;
      }
      venue.attendance = attendance;
      return venue;
    });

    this.setState({
      venues,
      raw_attendance: response.data,
      attendance: response.data,
      loading: false,
    });
    this.applyFilter();
  };
  state = {
    token: null,
    data: {},
    venues: [],
    loading: true,
  };
  render() {
    return (
      <div>
        {this.state.loading ? (
          <>
            <h4>Loading...</h4>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </>
        ) : (
          <Container classname="p-50 mt-20">
            <Row className="justify-content-md-center mt-50">
              <Filters
                venues={this.state.venues}
                setVenue={this.setVenue}
                applyFilter={this.applyFilter}
                dateBubble={(data) => {
                  this.setState(data);
                }}
              />
            </Row>
            {/* <Row className="justify-content-md-center">
              <LeagueTable venues={this.state.venues} />
            </Row> */}

            <Row className="justify-content-md-center">
              <ScoreCard
                metric="Total Number Attended"
                value={this.state.totalattendance}
              />
              <ScoreCard
                metric="Total Number of Incidents"
                value={this.state.totalIncidents}
              />
              <ScoreCard
                metric="Incident Rate Per 1000 Attended"
                value={
                  this.state.totalIncidents && this.state.totalattendance
                    ? (
                        this.state.totalIncidents /
                        (this.state.totalattendance / 1000)
                      ).toLocaleString()
                    : "Loading"
                }
              />
              <ScoreCard
                metric="Total Number of RIDDORs"
                value={this.state.totalRiddors}
              />
            </Row>
            <Row className="justify-content-md-center">
              {this.state.incidentByType ? (
                <ApexChart
                  data={this.state.incidentByType}
                  title="Incidents by Location"
                />
              ) : (
                <p>Loading</p>
              )}
            </Row>
            <Row className="justify-content-md-center">
              {this.state.incidentByLocationFixed ? (
                <ApexChart
                  data={this.state.incidentByLocationFixed}
                  title="Incidents by Fixed Location"
                />
              ) : (
                <p>Loading</p>
              )}
            </Row>
          </Container>
        )}
      </div>
    );
  }
}

export default Dashboard;
