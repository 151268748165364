import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Main from "./components/main";
import ApexChart from "./components/ApexChart";

function App() {
  return (
    <div className="App">
      <Main />
    </div>
  );
}

export default App;
