import React, { Component, useEffect, useState } from "react";
import { Table } from "react-bootstrap";

function LeagueTable({ venues }) {
  const [stateVenues, setStateVenues] = useState([]);
  let storedVenues = [];
  useEffect(() => {
    storedVenues = [];
    for (let i = 0; i < venues.length; i++) {
      const venue = venues[i];
      calcTotals(venue);
    }
    console.log(storedVenues);
    setStateVenues(storedVenues);
  }, []);
  const calcTotals = (venue) => {
    if (venue["attendance"] && venue["incidents"]) {
      venue.totalAttendace = totalattendance(venue["attendance"]);
      venue.totalIncidents = venue.incidents.length;
      storedVenues.push(venue);
    }
  };
  const totalattendance = (attendanceArr) => {
    return attendanceArr.reduce(function (acc, obj) {
      return acc + obj.total_attended;
    }, 0);
  };
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Venue</th>
          <th>Total Incidents</th>
          <th>Total Attendance</th>
          <th>Incident Rate</th>
        </tr>
      </thead>
      <tbody>
        {stateVenues.map((venue) => {
          return (
            <tr>
              <td></td>
              <td>{venue.venue_name}</td>
              <td>{venue.totalIncidents.toLocaleString()}</td>
              <td>{venue.totalAttendace.toLocaleString()}</td>
              <td>
                {(
                  venue.totalIncidents /
                  (venue.totalAttendace / 1000)
                ).toLocaleString()}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

export default LeagueTable;
