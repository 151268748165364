import React, { Component } from "react";
import { Card } from "react-bootstrap";

class ScoreCard extends Component {
  state = {};
  roundNumber = (number) => {
    if (typeof number == "number") {
      return number.toLocaleString();
    } else {
      return number;
    }
  };
  render() {
    return (
      <Card className="m-3" style={{ width: "18rem" }}>
        <Card.Body>
          <Card.Title>{this.props.metric}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            {this.roundNumber(this.props.value)}
          </Card.Subtitle>
        </Card.Body>
      </Card>
    );
  }
}

export default ScoreCard;
