import React, { Component } from "react";
import { Form } from "react-bootstrap";

class DatePicker extends Component {
  processInput = (e) => {
    this.props.bubble(e.target.value);
  };
  state = {};
  render() {
    return (
      <Form.Group controlId={this.props.label}>
        <Form.Label>{this.props.label}</Form.Label>
        <Form.Control
          type="date"
          name={this.props.label}
          onInput={this.processInput}
        />
      </Form.Group>
    );
  }
}

export default DatePicker;
