import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import Col from "react-bootstrap/Col";

class ApexChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }
  componentDidUpdate = () => {};
  componentDidMount = () => {
    this.setState({
      series: this.props.data.series,
      options: {
        title: {
          text: this.props.title,
          align: "center",
        },
        chart: {
          width: "100%",
          type: "pie",
        },
        legend: {
          // show: false,
          position: "bottom",
        },
        labels: this.props.data.labels,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 500,
              },
            },
          },
        ],
      },

      loading: false,
    });
  };

  render() {
    if (this.state.loading) {
      return <p>Loading</p>;
    } else
      return (
        <Col className="justify-content-md-center">
          <div className="chart-div">
            <ReactApexChart
              options={{
                title: {
                  text: this.props.title,
                  align: "center",
                },
                chart: {
                  width: "100%",
                  type: "pie",
                },
                legend: {
                  // show: false,
                  position: "bottom",
                },
                labels: this.props.data.labels,
                responsive: [
                  {
                    breakpoint: 480,
                    options: {
                      chart: {
                        width: 500,
                      },
                    },
                  },
                ],
              }}
              series={this.props.data.series}
              type="pie"
              width="100%"
            />
          </div>
        </Col>
      );
  }
}

export default ApexChart;
