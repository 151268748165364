import { useState } from "react";
import { supabase } from "../../supabaseClient";
import { Form, Button } from "react-bootstrap";

export default function Auth({ setSession }) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [OTP, setOTP] = useState("");
  const [OTPform, setOTPform] = useState(false);

  const [error, setError] = useState(null);

  const getRedirect = () => {
    console.log("ENV", process.env.NODE_ENV);
    let output = { emailRedirectTo: null };
    if (process.env.NODE_ENV == "development") {
      output.emailRedirectTo = "http://localhost:3000/";
    } else {
      output.emailRedirectTo = "https://nwuksafety.com/dashboard/";
    }
    return output;
  };

  const handleOTP = async (e) => {
    e.preventDefault();
    console.log(OTP);
    try {
      const { data, error } = await supabase.auth.verifyOtp({
        email: "jack.jury@eventsafetyplan.com",
        token: OTP,
        type: "magiclink",
      });
      if (error) {
        throw error;
      }
      console.log(data);
      setSession(data);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // options: {
      //   emailRedirectTo: "http://localhost:3000/",
      // },
      setLoading(true);
      const { error } = await supabase.auth.signInWithOtp({
        email,
        options: getRedirect(),
        // options: {
        //   emailRedirectTo: "https://nwuksafety.com/dashboard/",
        // },
      });
      if (error) throw error;
      alert("Check your email for the login link!");
    } catch (error) {
      alert(error.error_description || error.message);
      setError(error.error_description || error.message);
    } finally {
      setLoading(false);
      setOTPform(true);
    }
  };

  if (OTPform) {
    return (
      <>
        <div className="row flex-center flex">
          <div className="col-6 form-widget login" aria-live="polite">
            <p className="description">Enter your One Time Passcode</p>
            <p>
              You can either follow the link in your email, or enter the one
              time passcode here
            </p>
            <Form onSubmit={handleOTP}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  id="email"
                  className="inputField"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Form.Label>One Time Passcode</Form.Label>

                <Form.Control
                  type="text"
                  placeholder="Enter Your Code"
                  id="otp"
                  className="inputField"
                  value={OTP}
                  onChange={(e) => setOTP(e.target.value)}
                />
              </Form.Group>

              <Button variant="primary" type="submit">
                Submit
              </Button>
              <p></p>
              <a
                className=""
                variant="primary"
                onClick={() => {
                  setOTPform(false);
                }}
              >
                Back
              </a>
            </Form>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="row flex-center flex">
        <div className="col-6 form-widget login" aria-live="polite">
          <p className="description">
            Sign in via magic link with your email below
          </p>
          {loading ? (
            "Sending magic link..."
          ) : (
            <>
              <Form onSubmit={handleLogin}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    id="email"
                    className="inputField"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Form.Text className="text-muted">
                    We will email you a magic link to log in
                  </Form.Text>
                </Form.Group>

                <Button variant="primary" type="submit">
                  Submit
                </Button>
                <p></p>
                <a
                  className=""
                  variant="primary"
                  onClick={() => {
                    setOTPform(true);
                  }}
                >
                  Enter One Time Passcode
                </a>
              </Form>
            </>
          )}
        </div>
      </div>
    );
  }
}
